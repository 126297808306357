import '../styles/global.scss';
import '../styles/pages/privacy.scss';

import {HeadFC, Link} from "gatsby";

import {
    BigLogoImage
} from "../images";
import React, {useState} from 'react'
import MainFooter from '../components/MainFooter';

const Eula = () => {

    return (
        <>
            <div className="DocPage">
                <div className="DocPageLogo">
                    <Link to="/">
                        <img src={BigLogoImage} width={200} alt=""/>
                    </Link>
                </div>
                <div className="DocPageWrapper">
                    <h2>End-user license agreement</h2>
                    <p>Read the Privacy Policy <Link to="/privacy">here.</Link></p>
                    <p>Last Updated: February 22, 2023</p>
                    <p>These Terms of Service (the <b>"Terms"</b>) govern your access to and use of the services provided by AEER Platform Inc, (<b>"Apalon", "we" , "us"</b> or <b>"our"</b>), including its mobile applications, websites, email notifications, and any and all related services (collectively, the <b>"Services"</b>). These Terms also govern your access to any information, text, graphics, photos, audio, video or other materials made available through the Services (the <b>"Content"</b>).</p>
                    <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE ACCESSING OR USING ANY OF THE SERVICES.</p>
                    <h3>CONTENTS</h3>
                    <ul>
                        <li>A. AGREEMENT</li>
                        <li>B. AGE REQUIREMENT</li>
                        <li>C. GRANT OF LICENSE</li>
                        <li>D. DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS</li>
                        <li>E. FEATURES & FUNCTIONALITY</li>
                        <li>F. CONTENT ON THE SERVICES</li>
                        <li>G. SECURITY</li>
                        <li>H. REGISTRATION/PASSWORDS</li>
                        <li>I. UNINSTALL / REMOVAL OF AN APP</li>
                        <li>J. CONSENT TO USE OF DATA AND USER REVIEWS</li>
                        <li>K. INTELLECTUAL PROPERTY</li>
                        <li>L. COPYRIGHT</li>
                        <li>M. TERMINATION</li>
                        <li>N. DISCLAIMER OF WARRANTY</li>
                        <li>O. LIMITATION OF LIABILITY</li>
                        <li>P. INDEMNIFICATION</li>
                        <li>Q. EXPORT CONTROLS</li>
                        <li>R. NOTICE TO US GOVERNMENT END USERS</li>
                        <li>S. JURISDICTIONAL AVAILABILITY</li>
                        <li>T. DISPUTE RESOLUTION AND OTHER MISCELLANEOUS TERMS</li>
                        <li>U. HOW TO CONTACT US.</li>
                        <li>V. SUPPLEMENTAL TERMS</li>
                        <li></li>
                        <li></li>
                    </ul>

                    <h3>A. AGREEMENT</h3>
                    <p>These Terms are a legal agreement between you and Apalon. By using the Services, including installing and/or downloading any of our mobile applications (each an <b>"App"</b> and collectively the <b>"Apps"</b>), you: (a) agree to be bound by these Terms, (b) you represent and warrant that you have the right, authority and capacity to enter into these Terms; and (c) you own or control the mobile device by which you are accessing the Services. These Terms also apply to any App updates, supplements, and services made available by Apalon that are not provided under a separate license or other agreement with us. <b>If you do not agree to these Terms, do not install or use any App or Services.</b> We may amend these Terms from time to time and will notify you of any update to the Terms that materially impact your use of the Services or your rights or obligations. Such notification may be through an in-app notification or website notice. Your continued use of the Services after we post any amendments to these Terms will signify your acceptance of such amendments. <b>If you do not agree with any amendment, you must discontinue using the Services (including any Apps).</b> If you have any questions or concerns regarding these Terms, please contact support at the email provided in Section U below. Do not use the Services until your questions and concerns have been answered to your satisfaction and you agree to abide by these Terms.</p>

                    <p><b>Notice to consumers</b> Depending on the laws of the jurisdiction where you live, you may have certain rights that are in addition to these Terms, and certain provisions of these Terms may be unenforceable as to you. To the extent that any term or condition of these Terms is unenforceable to you, the remainder of these Terms shall remain in full force and effect.</p>

                    <p>Use of the Services is subject to our <Link to="/privacy">Privacy Policy</Link>, which is hereby incorporated into these Terms by reference. These Terms also include any additional payment terms and other requirements set forth on the download or purchase page through which you purchase or download any Apps. The Apps may be available through marketplaces that distribute mobile applications and that may have additional terms, conditions and usage rules that govern your use of the Apps if you download or install the Apps through such marketplaces.</p>

                    <h3>B. AGE REQUIREMENT</h3>

                    <p><b>You must be 13 years of age (or 16 in the EEA) or older to install or to use the Apalon Apps.</b></p>

                    <h3>C. GRANT OF LICENSE</h3>
                    <p>Subject to your compliance with these Terms, Apalon grants you a limited, non-exclusive, revocable, non-sublicensable, non-transferable license, to access, download and/or install the most current generally available version of the software provided to you as part of the Services solely for your lawful, personal, and non-commercial use.</p>

                    <h3>D. DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS</h3>
                    <b>Restricted Use.</b>

                    <p><b>Play Store.</b> European Union residents normally have a right to cancel online purchases</p>
                    <p>You may not rent, sell, lease, sublicense, distribute, assign, copy (other than a single copy for your own backup purposes), or in any way transfer or grant any rights to the Services or use the Services for the benefit of any third party. Unless expressly authorized by Apalon, you are prohibited from making the Apps available over a network where it could be downloaded or used by multiple users. You agree that you will not use any robot, spider, other automatic or manual device or process to interfere or attempt to interfere with the proper working of the Services, except to remove our Apps from a mobile device which you own or control. You may not violate or attempt to violate the security of our Services. You may not modify, reverse-engineer, decompile, disassemble, or otherwise tamper with any Services, or attempt to do so for any reason or by any means. You may not access, create or modify the source code of any Services in any way. You do not have the right to and may not create derivative works of any Services or any portions thereof. All modifications or enhancements to the Services remain the sole property of Apalon.</p>

                    <p><b>Updates.</b> We reserve the right to add or remove features or functions to existing Services. When installed on your mobile device, the Apps periodically communicate with our servers. We may require the updating of the Apps on your mobile device when we release a new version of the Apps, or when we make new features available. This update may occur automatically or upon prior notice to you, and may occur all at once or over multiple sessions. You understand that we may require your review and acceptance of our then-current Terms before you will be permitted to use any updated versions of the Services. You acknowledge and agree that any obligation we may have to support previous versions of the Services may be ended upon the availability of updates, supplements or subsequent versions. You acknowledge and agree that we have no obligation to make available to you any updates, supplements or updated versions of the Services.</p>

                    <p><b>Access.</b> You must provide at your own expense the equipment, Internet connections, devices and service plans to access and use the Apps. If you access an App through a mobile network, your network or roaming provider's messaging, data and other rates and fees may apply. You are solely responsible for any costs you incur to access the Apps from your device. Downloading, installing or using certain Apps may be prohibited or restricted by your network provider and not all Apps may work with your network provider or device. Apalon makes no representation that the Apps can be accessed on all devices or wireless service plans. Apalon makes no representation that the Apps are available in all languages or that the Apps are appropriate or available for use in any particular location.</p>

                    <p><b>Purchases & Cancellation Rights.</b> Subscriptions. You may purchase certain Services on a subscription basis. Some Services may offer different subscription levels (e.g., free or premium) or subscribption periods (e.g., monthly, yearly). Payment for a subscription may be processed in-App, through a third party mobile platform owner (e.g. Apple or Google) or directly through Apalon and its third party payment processor.</p>
                    <p>You may be offered a free trial of a subscription to our Services for a limited period of time. You are free to cancel a free trial subscription at any time via the subscription setting in your iTunes or Android account, or if you went through one of our websites, by logging in on the applicable website to manage your subscriptions. Please note: your free trial subscription will automatically renew as a paid subscription for the applicable subscription period unless you cancel at least 24 hours before the end of the free trial subscription period or, if you purchased through Google, before the end of the trial period.</p>
                    <p>If you subscribed for our Services by purchasing and installing an App through a third party mobile platform owner (either through the third party platform or in-App), you may cancel your subscription at any time by following the Apple App Store or Google Play Store instructions or via the subscription settings in your iTunes or Android account. If you subscribed for our Services directly through our website, you may cancel your subscription at any time by logging in to manage your subscriptions on the applicable website.</p>
                    <p>All cancellations will take effect starting at the end of the then current billing period. For example, if you have a monthly subscription that began on the first of the month but cancel mid-month, your cancellation will be effective as of the end of that month. There are no refunds on subscriptions once the charge has gone through for the billing period. Paid Applications. Certain Apps are available for one-time purchase (no recurring subscription) through either the Apple App or Google Play Store. European Union residents normally have a right to cancel online purchases within 14 days of making them. Please note and acknowledge that if you are resident in the European Union and purchase an App from a mobile platform owner, you may not be able to cancel your order or obtain a refund. Please review the mobile platform owner's terms in this regard before purchase. <br /> You may uninstall paid applications at any time at your discretion. All purchases of paid applications are non-refundable.</p>

                    <p><b>Automatic Renewal; Recurring Charges.</b> Paid subscriptions to the Services are auto-renewing. You will be charged the stated subscription amount for the subscription period upon completion of your registration and submission of your payment information or, if applicable, at the end of your free trial period if you have not canceled at least 24 hours in advance of the expiration of the trial period. You hereby agree to and authorize automatic and recurring billing, and you agree to pay the charges made to your account in connection therewith. You will be billed on a recurring basis at the then-current applicable subscription price plus any applicable taxes at the start of each new subscription period. Payments for renewal subscriptions are processed using the same billing cycle as your current subscription. Subscription fees may change at any time, to the fullest extent permitted under applicable law. Your subscription, and monthly billing of your account, will continue indefinitely until cancelled by you in accordance with the Cancellation Section of this Agreement.</p>

                    <h3>E. FEATURES & FUNCTIONALITY</h3>
                    <p>The various features and functionalities made available through the Services may change from time to time and are provided by Apalon and third party suppliers (the "Third Party Partners") who offer Content and/or services in conjunction with or through the Services and other users who may share Content on through their use of the Services. The Services may provide you with location data for basic navigational purposes only and are not intended to be relied upon in situations where precise location information is needed or where erroneous, inaccurate or incomplete location data may lead to death, personal injury, property or environmental damage. Neither Apalon, nor any of its Third Party Partners, guarantee the availability, accuracy, completeness, reliability, or timeliness of location data displayed through the Services.</p>

                    <h3>F. CONTENT ON THE SERVICES</h3>
                    <p>User Content. The Apps may allow users to submit and post Content that will be made public and available to other App users ("User Content"). You are responsible for your use of the Services and for any User Content you provide, including compliance with applicable laws, rules, and regulations. You should only provide User Content that you are comfortable sharing with others. Apalon may also act to remove Objectionable Content. The decision to remove Objectionable Content shall be made at Apalon’s sole discretion.</p>
                    <p>Apalon may terminate a user's access to the Services if, under appropriate circumstances, the user is determined to be a repeat infringer.</p>
                    <p>You agree that you shall not post or share User Content on the Services that Apalon deems Objectionable Content. "Objectionable Content" includes, but it not limited to:</p>
                    <ul>
                        <li>Commercial in nature (including advertising or solicitations);</li>
                        <li>Infringes the intellectual property rights of others;</li>
                        <li>Is unlawful, defamatory, libelous or infringes the privacy rights of others;</li>
                        <li>Presents a risk to a person’s security, health or safety, including minors;</li>
                        <li>Entices criminal or unlawful behavior; 6. falsely states or implies that such User Content is endorsed or sponsored by Apalon or any of its Third Party Partners;</li>
                        <li>Is vulgar, threatening, or considered hate speech intended to offend or harass individuals or a group of individuals based on race, ethnicity, national origin, disability, religious affiliation, sexual orientation, sex, or gender identity;</li>
                        <li>Contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; and</li>
                        <li>Is otherwise in contravention of these Terms.</li>
                    </ul>
                    <p>You represent and warrant that you own or have the necessary rights in and to the Content that you post, including the right to post or share the User Content publicly through the Services and to permit Apalon to publish and use such User Content as contemplated by these Terms. By submitting User Content to Apalon you are hereby granting Apalon and its users an irrevocable, worldwide, perpetual, royalty-free, non-exclusive, sub-licensable and transferrable license to copy, distribute, use, print, publish, republish, reformat, translate, modify, revise and incorporate into other works, prepare derivative works of and display in public in the Apps or in any form of media or expression. The User Content you submit to Apalon may be used by Apalon commercially at Apalon's sole discretion.</p>
                    <p>Apalon makes no representations about the User Content that you or any user shares through the Services. You are responsible for obtaining all necessary permissions and rights before using any User Content not posted by you. You agree to accept all risks associated with the use of any User Content, including any reliance on the accuracy or completeness of such User Content. Under no circumstances will Apalon be liable in any way for any User Content including, but not limited to, any errors or omissions in any such User Content, or for loss or damage of any kind incurred as a result of the transmission or posting of such User Content through the Services.</p>
                    <p><b>Content Screening and Disclosure.</b> We do not, and cannot, pre-screen or monitor all Content. However, our representatives may monitor Content submission through the Apps, and you hereby provide your irrevocable consent to such monitoring. You acknowledge and agree that you have no expectation of privacy concerning the submission of any Content. We have the right, but not the obligation, in our sole discretion to edit, refuse to post, or remove any Content.</p>
                    <p>We may access, preserve or disclose any of your information or Content (including without limitation chat text) if we are required to do so by law, or if we believe in good faith that it is reasonably necessary to (i) respond to claims asserted against us or to comply with legal process (for example, subpoenas or warrants), including those issued by courts having jurisdiction over us or you; (ii) enforce or administer our agreements with users, such as these Terms; (iii) for fraud prevention, risk assessment, investigation, customer support, providing the app services or engineering support; (iv) protect the rights, property or safety of Apalon, its users, or members of the public or (v) to report a crime or other offensive behavior.</p>

                    <b>Third-Party Services and Content.</b>
                    <p>The Services may integrate, be integrated into, bundled, or be provided in connection with third-party services, advertising, feeds, and/or content. If you are installing an App that includes third party services and third party content, such services and content are subject to such third party's terms of services and privacy policies, which may be found on the relevant Third Party Partner's website. Our Services may provide access or links to Third Party Partner websites or resources. Apalon has no control over such websites and resources, and you acknowledge and agree that Apalon is not responsible for the availability of such external websites or resources, and does not endorse nor is responsible or liable for any content, advertising, products, or other materials on or available from such websites or resources. You further acknowledge and agree that Apalon shall not be responsible or liable, directly or indirectly, for any Third Party Partner Content, including any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any Third Party Partner content, goods or services available on or through the Services or any such Third Party Partner website or resource. Apalon will not be a party to or in any way be responsible for monitoring any transaction between you and Third Party Partners.</p>

                    <h3>G. SECURITY</h3>
                    <p>The Services, like other consumer technologies, may not be 100% secure. By accepting these Terms you acknowledge and accept that the Services may be exposed to unauthorized access, interception, corruption, damage or misuse, and cannot be regarded as 100% secure. You accept all responsibility for such security risks and any damage resulting therefrom. Further, you are solely responsible for securing any device used to access the Services from unauthorized access, including by such means as using complex password protection and enabling device encryption when available through settings on your device. You agree that Apalon shall not be liable for any unauthorized access to your account or devices.</p>

                    <h3>H. REGISTRATION/PASSWORDS</h3>
                    <p>Registration. Some Services may not require a registration: however, some Services will permit or require you to create an account to participate or access additional features or functionalities ("Registration"). If such Registration is required, it will be made known to you when you attempt to participate or access such additional features or functionalities. Any registration required by a Third Party Partner is not governed by these Terms and you should refer to the relevant Third Party Partner's website for their policies.</p>
                    <p>Passwords. You are the sole and exclusive guardian of any password and ID combination issued or chosen by to you. Maintaining the confidentiality and security of your password(s) and ID(s) is solely your responsibility. You are fully responsible for all transactions undertaken by means of any account opened, held, accessed or used via your password and ID. You shall notify us immediately and confirm in writing any unauthorized use of accounts or any breach of security, including without limitation any loss, theft or unauthorized use of your password(s), and/or ID(s) or any related account. If we have reasonable grounds to suspect that the security of your password and/or ID has been compromised, we may suspend or terminate your account, refuse any and all current or future use of the services, and pursue any appropriate legal remedies. We shall not be responsible for any losses incurred in connection with any misuse of any password or ID.</p>
                    <p>Provided Information. If you provide any information in connection with a Registration, you must provide and maintain accurate, complete and current information. If we have reasonable grounds to suspect that your information is inaccurate, not current or not complete, we may suspend or terminate your use of the Services, and pursue any appropriate legal remedies.</p>
                    <p>You agree that we shall have the right to use the information you provide to us for the purposes described in these Terms and in furtherance of your use of the App and our services, in accordance with the <Link to="/privacy">Privacy Policy.</Link></p>

                    <h3>I. UNINSTALL/REMOVAL OF AN APP</h3>
                    <p>Uninstallation and removal methods vary depending on your device. To uninstall and remove the Apps, please use the application manager provided with your device or consult your device manual for reference. Please remember if you have a paid subscription to an App, uninstalling will not cancel your recurring payments. See our cancellation instructions above for more information.</p>

                    <h3>J. CONSENT TO USE OF DATA AND USER REVIEWS</h3>
                    <p>You agree that we may collect and use technical data and related information, including but not limited to technical information about your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support and other services to you (if any) related to the Services. We may use this information in accordance with the Privacy Policy.Additionally, if you choose to provide app store reviews or reviews via any social medial channel, or other similar communication or messaging features or services, such information may be made publicly available, including the public-facing username as it appears with the review. If you prefer that we do not use your app reviews for promotional purposes, you will be able to elect for us not to do so by contacting the support email in Section U below (please include your name, mailing address, and email address). For security purposes, please do not include any password, social security number, payment card or other sensitive information via these features. We have the right, but not the obligation to monitor messages and communications between and among users for security and training purposes. We may, but are not obligated to, remove any content we deem inappropriate.</p>

                    <h3>K. INTELLECTUAL PROPERTY</h3>
                    <p>The Services, including all Content, design, text, images, photographs, illustrations, audio-clips, video-clips, artwork, graphic material, code, content, protocols, software, and documentation provided to you by Apalon are Apalon’s property or the property of Apalon’s licensors, and are protected by U.S. and international copyright, trademarks, patents and other proprietary rights and laws relating to Intellectual Property Rights. "Intellectual Property Rights" means, collectively, rights under patent, trademark, copyright and trade secret laws, and any other intellectual property or proprietary rights recognized in any country or jurisdiction worldwide, including, without limitation, moral or similar rights. You may not delete, alter, or remove any copyright, trademark, or other proprietary rights notice we or Third Party Partners have placed on or within the Services. All rights not expressly granted hereunder are expressly reserved to Apalon and its licensors.</p>
                    <p>Apalon and any names, logos and affiliated properties, are the exclusive property of Apalon or its affiliates. All other trademarks appearing through the Services, including in any App, are trademarks of their respective owners, and the use of such trademarks shall inure to the benefit of the trademark owner. Our partners or service providers may also have additional proprietary rights in the content which they make available through an Services. The trade names, trademarks and service marks owned by us, whether registered or unregistered, may not be used in connection with any product or service that is not ours, in any manner that is likely to cause confusion. Nothing contained in herein should be construed as granting, by implication, estoppel or otherwise, any license or right to use any of our trade names, trademarks or service marks without our express prior written consent.</p>

                    <h3>L. COPYRIGHT</h3>
                    <p>Copyright/Submissions. You are solely responsible for any Content you contribute, submit, display or for any adaptations of works made on or through your use of the App(s).</p>
                    <p>It is your obligation to ensure that such Content, including photos, text, video and music files, does not violate any copyright or other Intellectual Property Rights. You must either own or have a license to use any Content that you contribute, submit or display. Apalon respects and expects its users to respect the rights of copyright holders. On notice, Apalon will act appropriately to remove content that infringes the copyright rights of others. Apalon reserves the right to disable the access to Apps or other services by anyone who uses them to repeatedly infringe the Intellectual Property Rights of others. If you believe an App, or elements, infringe your copyright rights, Please contact Apalon’s Copyright Agent at: AEER Platform Inc</p>
                    <p>© AEER PLATFORM INC 8 THE GREEN STE A DOVER, DE 19901 18th Street <br />
                    Attn: Copyright Agent / Legal Department <br />
                    Email: ceo@aeer-platform.com <br />
                    Please ensure your communication includes the following:
                    </p>
                    <ul>
                        <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
                        <li>a description of the copyrighted work that you claim has been infringed;</li>
                        <li>a description of where the material that you claim is infringing is located on the App;</li>
                        <li>your address, telephone number, and email address;</li>
                        <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
                        <li>a statement by you that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</li>
                    </ul>
                    <p>For other support please see Section U.</p>

                    <h3>M. TERMINATION</h3>
                    <p>Your rights under these Terms will terminate immediately and automatically without any notice from Apalon if you fail to comply with any of the terms and conditions of these Terms. You understand that Apalon, in its sole discretion, may modify or discontinue or suspend your right to access any of our services or use of any Services at any time. Further, Apalon, with or without any reason, may at any time suspend or terminate any license hereunder and disable the Services, including any Apps or any of the component features of the Services. You agree that Apalon shall not be liable to you or any third-party for any termination or disabling of the Services. Promptly upon expiration or termination of these Terms, you must cease all use of the Services and destroy all copies of the Services, including any Apps in your possession or control. Termination will not limit any of Apalon’s other rights or remedies at law or in equity. Sections J – S, and any Supplemental Terms of these Terms shall survive termination or expiration of these Terms for any reason.</p>

                    <h3>N. DISCLAIMER OF WARRANTY</h3>
                    <p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, ALL SERVICES ARE PROVIDED ON AN "AS IS," "WITH ALL FAULTS," AND "AS AVAILABLE" BASIS, AND YOU USE THEM AT YOUR SOLE RISK. SUBJECT TO APPLICABLE LAW, APALON, ON BEHALF OF ITSELF, AND ITS PARENT, AFFILIATES, LICENSORS, DISTRIBUTORS, VENDORS, AGENTS AND SUPPLIERS, EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND ANY OTHER WARRANTY ARISING UNDER THE SALE OF GOODS ACTS 1893 AND 1980, USAGE OF TRADE, COURSE OF CONDUCT OR OTHERWISE. WITHOUT LIMITATION, APALON MAKES NO WARRANTY THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, THAT THEY WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, THAT THE RESULTS OBTAINED FROM THE USE OF THE APALON PRODUCTS AND SERVICES WILL BE ACCURATE OR RELIABLE, OR THAT THE QUALITY OF THE APPS WILL MEET YOUR EXPECTATIONS.</p>
                    <p>APALON ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERVICES; ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SERVICES OR SERVERS; ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY; OR ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.</p>
                    <p>THE ENTIRE RISK ARISING OUT OF USE OR PERFORMANCE OF THE SERVICES REMAINS SOLELY WITH YOU. APALON EXPRESSLY DISCLAIMS ALL WARRANTIES RELATING TO PRODUCTS AND/OR SERVICES PROVIDED BY THIRD PARTY PARTNERS.SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES. IN SUCH JURISDICTIONS, THE FOREGOING DISCLAIMERS MAY NOT APPLY TO YOU INSOFAR AS THEY RELATE TO IMPLIED WARRANTIES.THIS DISCLAIMER OF WARRANTY CONSTITUTES AN ESSENTIAL PART OF THIS AGREEMENT.</p>

                    <h3>O. LIMITATION OF LIABILITY</h3>
                    <p>TO THE EXTENT PERMITTED BY APPLICABLE LAWS, YOU EXPRESSLY UNDERSTAND AND AGREE THAT APALON AND ITS PARENTS, AFFILIATES AND THIRD PARTY PARTNERS SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF APALON HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SERVICES; (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (III) STATEMENTS OR CONDUCT OF ANY THIRD PARTY; OR (IV) ANY OTHER MATTER RELATING TO THE SERVICES. IN NO EVENT SHALL APALON’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE SERVICES. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>
                    <p>IF ANY OF THE EXCLUSIONS SET FORTH IN THIS SECTION IS DETERMINED BY A COURT OF COMPETENT JURISDICTION TO BE UNENFORCEABLE, THEN ALL SUCH EXPRESS, IMPLIED AND STATUTORY WARRANTIES SHALL BE LIMITED IN DURATION FOR A PERIOD OF THIRTY (30) DAYS AFTER THE DATE ON WHICH YOU FIRST ACCESS THE SERVICES, AND NO WARRANTIES SHALL APPLY AFTER SUCH PERIOD.</p>

                    <h3>P. INDEMNIFICATION</h3>
                    <p>YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS APALON, ITS PARENTS, AFFILIATE AND SUBSIDIARY COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES, CONSULTANTS AND AGENTS FROM ANY AND ALL THIRD PARTY CLAIMS, LIABILITY, DAMAGES AND/OR COSTS (INCLUDING, BUT NOT LIMITED TO, ATTORNEYS' FEES) ARISING FROM YOUR USE OF THE SERVICES, YOUR VIOLATION OF THESE TERMS OR YOUR INFRINGEMENT, OR INFRINGEMENT BY ANY OTHER USER OF YOUR ACCOUNT, OF ANY INTELLECTUAL PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY. YOU AGREE TO IMMEDIATELY NOTIFY APALON OF ANY UNAUTHORIZED USE OF YOUR ACCOUNT OR ANY OTHER BREACH OF SECURITY KNOWN TO YOU.</p>

                    <h3>Q. EXPORT CONTROLS</h3>
                    <p>The Services and the underlying information and technology are subject to U.S. and international laws, restrictions and regulations that may govern the import, export, downloading and use of the Apps. You agree to comply with these laws, restrictions and regulations when downloading or using the Apps.</p>

                    <h3>R. NOTICE TO US GOVERNMENT END USERS</h3>
                    <p>Any Services used or Apps installed for or on behalf of the United States of America, its agencies and/or instrumentalities ("U.S. Government"), is provided with Restricted Rights as "Commercial Items," as that terms is defined at 48 C.F.R. §2.101, consisting of "Commercial Computer Software" and "Commercial Computer Software Documentation," as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Pursuant to Federal Acquisition Regulation 12.212 (48 C.F.R. §12.212), the U.S.</p>
                    <p>Government shall have only those rights specified in the license contained herein. The U.S. Government shall not be entitled to (i) technical information that is not customarily provided to the public or to (ii) use, modify, reproduce, release, perform, display, or disclose commercial computer software or commercial computer software documentation except as specified herein. Use, duplication, or disclosure by the U.S. Government is subject to restrictions as set forth in subparagraph (c)(1)(ii) of the Rights in Technical Data and Computer Software clause at DFARS 252.227-7013 or subparagraphs (c)(1) and (2) of the Commercial Computer Software - Restricted Rights at 48 C.F.R. 52.227-19, as applicable.</p>

                    <h3>S. JURISDICTIONAL AVAILABILITY</h3>
                    <p>Apalon does not represent or warrant that the Services or any part thereof is appropriate or available for use in any particular jurisdiction. We may limit the availability of the Apps, in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in our sole discretion.</p>

                    <h3>T. DISPUTE RESOLUTION AND OTHER MISCELLANEOUS TERMS</h3>
                    <p>Except for members residing within the European Economic Area and elsewhere where prohibited by applicable law:</p>
                    <p>The exclusive means of resolving any dispute or claim arising out of or relating to these Terms (including any alleged breach thereof) or the Services shall be BINDING ARBITRATION administered by the American Arbitration Association. The number of arbitrators shall be one. The seat, or legal place, of arbitration and the governing law shall be New York County in the State of New York. The language to be used in the arbitral proceedings shall be English.</p>
                    <p>The one exception to the exclusivity of arbitration is that either party has the right to bring an individual claim against the other in a small-claims court of competent jurisdiction or, if filed in arbitration, the responding party may request that the dispute proceed in a small claims court of competent jurisdiction if the party’s claim is eligible for a small claims proceeding. If the responding party requests to proceed in small claims court before the appointment of the arbitrator, the arbitration shall be administratively closed, and if requested after the appointment of the arbitrator, the arbitrator shall determine if the dispute should be decided in arbitration or if the arbitration should be administratively closed and decided through a small claims court proceeding. Whether you choose arbitration or small-claims court, you may not under any circumstances commence or maintain against the Company any class action, class arbitration, or other representative action or proceeding.</p>
                    <p>By using the Services in any manner, you agree to the above arbitration agreement. In doing so, YOU GIVE UP YOUR RIGHT TO GO TO COURT to assert or defend any claims between you and the Company (except for matters that may be undertaken through small claims court). YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION OR OTHER CLASS PROCEEDING. Your rights will be determined by a NEUTRAL ARBITRATOR, NOT A JUDGE OR JURY, and the arbitrator shall determine all issues regarding the arbitrability of the dispute. You are entitled to a fair hearing before the arbitrator. The arbitrator can grant any relief that a court can, but you should note that arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons. Any proceeding to enforce this arbitration agreement, including any proceeding to confirm, modify, or vacate an arbitration award, may be commenced in any court of competent jurisdiction. In the event that this arbitration agreement is for any reason held to be unenforceable, any litigation against the Company (except for small-claims court proceedings) may be commenced only in the courts of New York. You hereby irrevocably consent to the jurisdiction of those courts for such purposes. </p>

                    <b>Governing Law.</b>
                    <p>The laws of New York, without regard to conflict of laws principles, shall govern all matters relating to or arising from these Terms, and the use (or inability to use) the Services.</p>
                    <p>The online dispute settlement platform of the European Commission is available under http://ec.europa.eu/odr. Apalon does not take part in dispute settlement procedures in front of a consumer arbitration entity for members residing in the EU or European Economic Area.</p>
                    <p>No failure or delay by Apalon in exercising any right, power or privilege under these Terms will operate as a waiver thereof, nor will any single or partial exercise of any right, power or privilege preclude any other or further exercise thereof or the exercise of any other right, power, or privilege under these Terms. If any provision of these Terms shall be found unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these terms and shall not affect the validity and enforceability of any remaining provisions.</p>
                    <p>YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN THREE (3) YEARS AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>

                    <h3>U. HOW TO CONTACT US.</h3>
                    <p>If you have any questions about these Terms, please contact us by email or postal mail as follows:</p>
                    <p>AEER Platform Inc</p>
                    <p>© AEER PLATFORM INC 8 THE GREEN STE A DOVER, DE 19901, DE 19901 </p>
                    <p>ceo@aeer-platform.com</p>

                    <h3>V. SUPPLEMENTAL TERMS</h3>
                    <p>To the extent permitted by applicable law (and without limiting the above rights, remedies and obligations except the extent expressly in conflict with additional terms below), there may be additional terms that shall apply to your use of the Services.</p>
                    <p>Third Party Content or Images. Certain Services include content, footage and/or images (collectively "Images") licensed to Apalon by third parties such as Getty Images (U.S.), Inc., Unsplash, Pexel, Astria, Adobe Systems Inc. and Giphy ("Image Providers"). These Image Providers do not endose, sponsor or provide support for the Services. By utilizing these Services, you specifically acknowledge and agree that:</p>

                    <ul>
                        <li>You may only use the Images as enables by Services, for display in digital form or as otherwise directed by the Services. You shall not use or distribute any portion of any Images for any purpose whatsoever that violated any third party right, or any applicable law, rule or regulation.</li>
                        <li>You may not sell or re-sell Images, and may not modify or re-use Images, except as enabled through features or technology available in the Services.</li>
                        <li>
                            Images may not be used:
                            <ol>
                                <li>for pornographic, degamatory or other unlawful purposes;</li>
                                <li>for the purpose of enabling file-sharing of the image file; or</li>
                                <li>in logos, trademarks, services marks or any other branding or identifiers.</li>
                            </ol>
                        </li>
                        <li>If you use Images featuring an individual(s) in connection with a sensitive, unflattering or controversial subject, you must include a statement that the image is used for illustrative purposes only and the individual is a model.</li>
                        <li>You may not remove any metadata in Images, or reverse engineer, decompile, or disassemble the Services or any technology available through the Services to enable the download or use of Images on a stand-alone basis.</li>
                        <li>No ownership or copyrights to any Images are granted to you.</li>
                        <li>If an App gives you the opportunity to use Images through Unsplash or Pixabay, when you use an Unsplash or Pixabay Image, you agree to the Unsplash <a href="https://unsplash.com/terms">terms</a>, the Pixabay <a href="https://pixabay.com/service/terms/">terms</a> or the Astria <a href="https://www.astria.ai/terms">terms</a> and its <a href="https://www.astria.ai/content">Content Policy</a> and you're responsible for verifying that your use of the image complies with their terms.</li>
                        <li>Images are provided to you on a non-exclusive basis and you understand that other users may create and use similar or identical images.</li>
                    </ul>
                    <p>Weather Content and Attribution - We may use content from third party weather sources:</p>    

                </div>	
            </div>
            <MainFooter />
        </>
    )
}

export default Eula;

export const Head: HeadFC = () => (
    <>
        <title>Privacy - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);
